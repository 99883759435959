import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {ScreenSetContainer} from "../../../components/ScreenSetContainer";
import {navigateByUrl} from "../../../utilities/navigate";


export const Login = () => {
  const interval = useRef<any>();
  const [search, setSearch] = useSearchParams();
  const [startScreen, setStartScreen] = useState<string>();
  const context = useRef<any>({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleLogin = () => {
    if (search.get('gig_ssoToken')) {
      window.gigya.sso.continue();
    } else {
      const url = search.get('returnUrl') || process.env.REACT_APP_CONNECT_URL!;
      navigateByUrl(url, navigate);
    }
  }

  useEffect(() => {
    interval.current = setInterval(() => {
      if (window.gigya.isReady) {
        clearInterval(interval.current);
        window.gigya.hasSession().then((exists) => {
          if (exists) {
            handleLogin()
          } else {
            window.gigya.accounts.addEventHandlers(
              {
                onLogin: () => handleLogin()
              })
          }
        })
      }
    }, 100)
  }, [])

  useEffect(() => {
    const loginID = search.get('loginID') || search.get('gig_loginID');
    const email = decodeURIComponent(search.get('email') || search.get('_email') || '');

    context.current.loginID = loginID;
    context.current.email = email;
    context.current.allowAccountCreation = search.get('gig_allowRegistration')?.toLocaleLowerCase() === 'true';

    const event = search.get('event');
    search.delete('event');
    search.delete('loginID');
    setSearch(search);

    let screen: string;
    switch (event) {
      case 'SSO_FAILED':
        screen = 'gigya-sso-fail-screen';
        break;
      default:
        screen = 'gigya-start-screen';
        break;
    }
    setStartScreen(screen);
    setLoading(false);
  }, []);

  return <ScreenSetContainer startScreen={startScreen}
                             loading={loading}
                             context={context.current}
                             application={search.get('gig_application') || 'Connect'}/>
}