import {useMediaMatch} from "rooks";
import {BREAKPOINTS, Size} from "@laerdal/life-react-components";

export const useDynamicSize = () => {
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  return isLargeScreen ? Size.Large : isMediumScreen ? Size.Medium : Size.Small;
}

export const useDynamicInputSize = () => {
  const isLargeScreen = useMediaMatch(BREAKPOINTS.LARGE.replace('@media ', ''));

  return isLargeScreen ? Size.Medium : Size.Small;
}