import React, {useEffect} from 'react';
import {GlobalLoadingPage} from "@laerdal/life-react-components";
import {Navigate, Outlet, useLocation} from "react-router-dom";


export const AuthenticatedRoute = () => {

  const [authorized, setAuthorized] = React.useState<boolean | undefined>(undefined);
  const location = useLocation();

  useEffect(() => {
    window.gigya.accounts.session.verify({
      callback: (response) => {
        setAuthorized(response.errorCode === 0);
      }
    })
  }, []);

  let search =
    location.search
      ? location.search + "&gig_allowRegistration=true" + `&returnUrl=${encodeURIComponent(location.pathname + location.search)}`
      : `?gig_allowRegistration=true&returnUrl=${encodeURIComponent(location.pathname)}`;


  return authorized === undefined
    ? <GlobalLoadingPage/>
    : !authorized
      ? <Navigate
        to={`/login${search}`}
        replace={true}/>
      : <Outlet/>;
}