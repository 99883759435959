import styled from 'styled-components'

const FullWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
`;

export default FullWrapper;