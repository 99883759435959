import {Role} from "../models/role";

export const IsServiceUserRole = (role: Role | undefined) => {
  return role?.id.toLowerCase() === process.env.REACT_APP_SERVICE_USER_ROLE_ID?.toLowerCase() ||
    role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_USER_ROLE_ID?.toLowerCase();
};

export const IsServiceAdminRole = (role: Role | undefined) => {
  return role?.id.toLowerCase() === process.env.REACT_APP_SERVICE_ADMIN_ROLE_ID?.toLowerCase() ||
    role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_ADMIN_ROLE_ID?.toLowerCase();
};

export const IsServiceOwnerRole = (role: Role | undefined) => {
  return role?.id.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase() ||
    role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase();
};
