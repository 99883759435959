import React, {useContext} from 'react';
import Helmet from 'react-helmet';

import {GlobalLoadingPage} from '@laerdal/life-react-components';

import ConfigContext from '../../contexts/ConfigContext';
import useScript from '../../hooks/useScript';

const Proxy = () => {
  const config = useContext(ConfigContext);

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: '{loginURL: "/oidc/login", consentURL: "/oidc/consent", errorURL: "/error"}',
            src: 'https://sapcdc.laerdal.com/JS/gigya.oidc.js?apiKey=' + config?.apiKey,
          },
        ]}
      />

      <GlobalLoadingPage/>
    </>
  );
};

export default Proxy;
