import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './i18n';

import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import App from './App';
import {ConfigProvider} from './contexts/ConfigContext';
import {createRoot} from 'react-dom/client';
import {ToastProvider} from "@laerdal/life-react-components";

declare global {
  interface Window {
    gigya: any
    onGigyaServiceReadyHandlers: any[]
    onGigyaServiceReady: () => void
    SmartlingContextTracker: any
  }
}

window.onGigyaServiceReadyHandlers = [];

window.onGigyaServiceReady = () => {
  window.onGigyaServiceReadyHandlers?.forEach((handler: any) => handler());
}

// this hack stops gigya automatically handling the sso callback error 
window.onGigyaServiceReadyHandlers.push(() => {
  if (window.location.href.includes('sso/callback')) {
    window.gigya.thisScript.globalConf.ignoreInterruptions = true;
  }
})


const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <ConfigProvider>
      <ToastProvider>
        <App/>
      </ToastProvider>
    </ConfigProvider>
  </BrowserRouter>
);

