import React from 'react';
import styled from 'styled-components';

import FullWrapper from '../components/FullWrapper';
import { ReactComponent as Illustration } from '../assets/ill1.svg';

const LogoutContent = styled.div`
  text-align: center;
  width: fit-content;
`;

const LogoutTitle = styled.h1`
  font-size: 48px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  margin-top: 40px;
`;

const LogoutMessage = styled.p`
  font-size: 32px;
  line-height: 1.4;
  font-weight: normal;
  color: #fddee2;
  margin: 0;
`;

const LogoutWrapper = styled(FullWrapper)`
  background: #dc3449;
`;

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.gigya.accounts.logout({});
  }

  render() {
    return (
      <>
        <LogoutWrapper>
          <LogoutContent>
            <Illustration />
            <LogoutTitle>You are now logged out</LogoutTitle>
            <LogoutMessage>Welcome back later!</LogoutMessage>
          </LogoutContent>
        </LogoutWrapper>
      </>
    );
  }
}

export default Logout;
