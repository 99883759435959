import {useWillUnmount} from 'rooks';

enum State {
  Pending = 'pending',
  Resolved = 'resolved',
  Rejected = 'rejected',
}

class SuspendPromise<T> {
  static queries = new Map();

  static get<T>(cache: string, func: () => Promise<T>): SuspendPromise<T> {
    if (!this.queries.has(cache)) {
      this.queries.set(cache, new SuspendPromise<T>(func()));
    }

    return this.queries.get(cache) as SuspendPromise<T>;
  }

  static reset(cache: string) {
    SuspendPromise.queries.delete(cache);
  }

  response: any;
  status: State = State.Pending;
  suspender: Promise<any>;

  constructor(promise: Promise<T>) {
    this.suspender = promise.then(
      (res) => {
        this.status = State.Resolved;
        this.response = res;
      },
      (err) => {
        this.status = State.Rejected;
        this.response = err;
      },
    );
  }

  read<T>() {
    switch (this.status) {
      case State.Pending:
        throw this.suspender;
      case State.Rejected:
        throw this.response;
      case State.Resolved:
        return this.response;
    }
  }
}

export const useSuspendWhileFetching = <T>(
  func: () => Promise<T>,
  cache: string,
  options?: { resetOnUnmount: boolean }
): [T, () => void] => {
  const promise = SuspendPromise.get(cache, func);

  const data = promise.read();

  const reset = () => SuspendPromise.reset(cache);

  useWillUnmount(() => options?.resetOnUnmount && reset());

  return [data, reset];
};