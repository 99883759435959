import {NavigateFunction} from "react-router";

export const navigateByUrl = (url: string, navigate?: NavigateFunction, options?: {
  props?: any,
  target?: '_self' | '_blank' | '_parent' | '_top' | string
}) => {
  if (options?.props) {
    Object.keys(options?.props).forEach((key) => {
      url = url.replace(`{{${key}}}`, options?.props[key]);
    });
  }

  const isExternalURL = new URL(url, window.location.origin).origin !== window.location.origin;
  if (!isExternalURL && !!navigate) {
    navigate(url.replace(window.location.origin, ''));
  } else {
    const win = window.open(url, options?.target || '_self');
    if (!!win) {
      win.focus();
    }
  }
};
