import styled from "styled-components";
import {
  BREAKPOINTS,
  COLORS,
  ComponentTextStyle,
  ComponentXXSStyling,
  GlobalLoadingPage,
  HyperLink,
  Z_INDEXES,
  DropdownButton, SystemIcons, DropdownItem, LoadingIndicator, GlobalNavigationBar
} from "@laerdal/life-react-components";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import ConfigContext from "../contexts/ConfigContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
`;

const HeaderContainer = styled.div`
  pointer-events: none;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: ${COLORS.neutral_50};
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  ${BREAKPOINTS.MEDIUM} {
    padding: 24px;
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    padding: 32px;
    gap: 32px;
  }

  .screenset-container {
    flex: 1;
    width: 100%;
    min-width: 288px;
    max-width: 360px;
  }

  ${BREAKPOINTS.MEDIUM} {
    .screenset-container {
      width: 50%;
      min-width: 400px;
      max-width: 480px;
    }
  }
`;

const Links = styled.div`
  display: flex;
  gap: 8px;
  padding: 14px 0;
  align-items: center;
`;

const Separator = styled.div`
  width: 1px;
  height: 16px;
  display: flex;
  box-sizing: border-box;
  background: ${COLORS.neutral_600};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;

  a.link, a.link:visited {
    ${ComponentXXSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
    color: ${COLORS.neutral_600};
  }

  p {
    ${ComponentXXSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  min-height: 100vh;
  opacity: 1;
  box-sizing: border-box;
  z-index: ${Z_INDEXES.off_canvas};

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
`;

const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5) !important;
`;


interface Props {
  startScreen?: string;
  loading: boolean;
  context?: any;
  application?: string;
}

export const ScreenSetContainer = ({startScreen, context = {}, application, loading}: Props) => {
  const {languages} = useContext(ConfigContext) || {};
  const {t, i18n} = useTranslation();
  const year = new Date().getFullYear();
  const [screenLoaded, setScreenLoaded] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const [currentScreen, setCurrentScreen] = useState('');
  const contextRef = useRef(context);

  const showScreenSet = (screen: string) => {
    Object.keys(context).forEach((key) => {
      contextRef.current[key] = context[key];
    });

    contextRef.current.environment = process.env.REACT_APP_ENVIRONMENT;
    contextRef.current.lang = i18n.language;
    contextRef.current.onAfterScreenLoadFn = (e) => {
      setScreenLoaded(true);
      setCurrentScreen(`${e.screenSetID}/${e.currentScreen}`);
      setInitialLoad(false);
    };

    let screenName = screen.includes('/') ? screen.split('/')[1] : startScreen;
    let screenSetId = screen.includes('/') ? screen.split('/')[0] : 'Auth2-RegistrationLogin';

    window.gigya.accounts.showScreenSet({
      screenSet: screenSetId,
      startScreen: screenName,
      containerID: 'screenset-container',
      customLang: {
        application: application
      },
      regToken: context.regToken,
      lang: i18n.language.toLowerCase(),
      context: contextRef.current
    })
  };


  useEffect(() => {
    startScreen && showScreenSet(startScreen);
  }, [startScreen])

  const hide = !loading && screenLoaded;

  let dropdownItems: DropdownItem[] =
    [{
      value: i18n.language?.toLowerCase(),
      displayLabel: languages?.find(a => a.locale?.toLowerCase() === i18n.language.toLowerCase())?.name,
    }];
  dropdownItems.push(
    ...languages?.filter(a => a.locale?.toLowerCase() !== i18n.language.toLowerCase())
      .map((lang) => ({value: lang.locale?.toLowerCase(), displayLabel: lang.name,})) ?? []);

  if (dropdownItems.length > 1) {
    dropdownItems[1].showDividerAbove = true;
  }

  return (
    <>
      <Overlay className={hide ? 'hide' : ''}>
        {
          initialLoad &&
            <GlobalLoadingPage/>
        }
        {
          !initialLoad &&
            <LoaderBackground>
                <LoadingIndicator color={COLORS.white}/>
            </LoaderBackground>
        }
      </Overlay>
      <Container>
        <HeaderContainer>
          <GlobalNavigationBar maxWidth={1600} useMaxWidth={true} name={application}/>
        </HeaderContainer>
        <ContentContainer>
          <div id='screenset-container' className={'screenset-container'}></div>
          <Footer>

            <DropdownButton type={'text'}
                            icon={<SystemIcons.Language/>}
                            onClick={([lang]) => {
                              setScreenLoaded(false);
                              i18n.changeLanguage(lang).then(() => {
                                showScreenSet(currentScreen);
                              });
                            }}
                            scrollable={true}
                            value={[i18n.language?.toLowerCase()]}
                            items={dropdownItems}/>
            <Links>
              <HyperLink variant={'default'}
                         className={'link'}
                         href={'https://laerdal.com/support/customer-service/terms-of-use/'}>
                {t('Terms of Use')}
              </HyperLink>
              <Separator/>
              <HyperLink variant={'default'}
                         className={'link'}
                         href={'https://laerdal.com/support/customer-service/privacy-statement/'}>
                {t('Privacy Policy')}
              </HyperLink>
            </Links>
            <p>
              {t('Copyright © {{year}} Laerdal Medical. All rights reserved.', {year})}
            </p>
          </Footer>
        </ContentContainer>
      </Container>
    </>
  )

}