import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useSuspendWhileFetching} from "../../hooks/SuspendWhileFetching";
import {BREAKPOINTS, COLORS, PageWidth, SystemIcons} from "@laerdal/life-react-components";
import styled from "styled-components";
import ContentContainer from "../../components/ContentContainer";
import {ErrorCard} from "../../components/ErrorCard";
import SignInOnBehalfService from "./SignInOnBehalfService";


const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_200};
  box-sizing: border-box;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
  }

  min-width: 288px;

  ${BREAKPOINTS.MEDIUM} {
    min-width: 400px;
  }
`;


export const SignInOnBehalfPage = () => {
  const {id} = useParams<{ id: string }>();
  const {t} = useTranslation('SigninOnBehalf');

  const [result] = useSuspendWhileFetching(() => SignInOnBehalfService.SigninOnBehalf(id!), `sign_in_on_behalf_${id}`);

  return (
    <ContentContainer>
      {
        !result &&
        <ErrorCard title={t('Signin on behalf failed')}
                   description={[t('The signin request expired.')]}/>
      }
      {
        result &&
        <Card>
          <p>{t('You are successfully signed in. You will be redirected.')}</p>
        </Card>
      }
    </ContentContainer>
  )
}