import React, {useEffect} from 'react';

import {GlobalLoadingPage} from '@laerdal/life-react-components';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from 'axios';

export const Consent = () => {

  const [search] = useSearchParams();
  const navigate = useNavigate();

  const calculateSignature = (consent: any) => {
    axios({
      method: 'POST',
      url: '/api/signature',
      data: consent,
    })
      .then((response: any) => {
        navigate(`/oidc/proxy?mode=afterConsent&consent=${JSON.stringify(consent)}&sig=${response.data}`);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }


  useEffect(() => {
    const clientID = search.get('clientID');
    const context = search.get('context');
    const scope = search.get('scope')?.replace(/[+]/g, ' ');
    const UID = search.get('UID');

    const consent = {scope, clientID, context, UID, consent: true};

    calculateSignature(consent)
  }, []);


  return <GlobalLoadingPage/>;
}
