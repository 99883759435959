import axios, {AxiosInstance} from "axios";
import {ServiceInfo} from "../../../models/serviceInfo";


class ResetPasswordService {

  private _client: AxiosInstance;

  constructor() {
    this._client = axios.create();
  }

  private HandleLoginEvent = (service: ServiceInfo, search: URLSearchParams) => {
    if (search.get('gig_ssoToken')) {
      window.gigya.sso.continue();
    } else {
      window.location.href = service?.returnUrl || process.env.REACT_APP_CONNECT_URL!;
    }
  }

  RegisterLoginHandler = (service: ServiceInfo, search: URLSearchParams): Promise<void> => {
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (window.gigya.isReady) {
          clearInterval(interval);
          window.gigya.accounts.addEventHandlers(
            {
              onLogin: this.HandleLoginEvent.bind(this, service, search)
            }
          )
          resolve();
        }
      }, 100)
    })
  }

  GetServiceInfo = (): Promise<ServiceInfo> => {
    const apiKey = window.gigya.apiKey;
    return this._client.get<ServiceInfo>(`/api/service/info/reset/${apiKey}`)
      .then(a => a.data);
  };
}

export default new ResetPasswordService();