import React from 'react';
import {useTranslation} from "react-i18next";
import {ErrorCard} from "../../../components/ErrorCard";
import ContentContainer from "../../../components/ContentContainer";

interface Props {
  state: 'inactive' | 'invalid-login';
  email?: string;
}

export const InvitationError = ({state, email}: Props) => {

  const {t} = useTranslation('Invitation')

  const logout = () => {
    window.gigya.accounts.logout({
      callback: () => {
        window.location.reload();
      }
    });
  }

  return <ContentContainer>
    <ErrorCard title={state === 'inactive' ? t('Sorry, that link is no longer active') : t('Cannot accept invite')}
               description={state === 'invalid-login'
                 ? [
                   t('You are currently signed in as {{email}}.', {email: email}),
                   t('Sign out from this account to accept the invite and register with a different email address.')
                 ]
                 : [
                   t('The link may have been set to expire after a certain amount of time. Contact an account administrator at your organization for assistance.')
                 ]}
               actions={
                 state === 'inactive'
                   ? [{
                     componentType: 'hyperlink',
                     label: t('Return to Laerdal.com'),
                     href: 'https://laerdal.com',
                     target: '_self'
                   }]
                   : [{
                     componentType: 'button',
                     label: t('Sign out'),
                     onClick: () => logout()
                   }]}/>
  </ContentContainer>

}