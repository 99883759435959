import React, {useEffect, useRef, useState} from 'react';
import {ScreenSetContainer} from "../../../components/ScreenSetContainer";
import {useSearchParams} from "react-router-dom";
import {ServiceInfo} from "../../../models/serviceInfo";
import ResetPasswordService from "./ResetPasswordService";


export const ResetPassword = () => {

  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<ServiceInfo>();
  const [search] = useSearchParams();
  const context = useRef({} as any);


  useEffect(() => {
    // so that + sing is not decoded to space
    context.current.loginID = window.location.href.split('&loginID=')[1];

    ResetPasswordService.GetServiceInfo()
      .then((service) => ResetPasswordService.RegisterLoginHandler(service, search).then(() => service))
      .then(setService)
      .finally(() => setLoading(false));
  }, []);

  return <ScreenSetContainer startScreen={'gigya-reset-password-screen'}
                             loading={loading}
                             context={context.current}
                             application={service?.name || 'Connect'}/>
}