import React, {AnchorHTMLAttributes, MouseEventHandler} from 'react';
import styled from "styled-components";
import {BREAKPOINTS, Button, COLORS, HyperLink, Size, SystemIcons} from "@laerdal/life-react-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_200};
  box-sizing: border-box;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
  }

  min-width: 288px;

  ${BREAKPOINTS.MEDIUM} {
    min-width: 400px;
  }
`;


type ButtonProps = {
  componentType: 'button',
  onClick: MouseEventHandler<HTMLButtonElement>,
  label: string,
}

type HyperlinkProps = {
  componentType: 'hyperlink',
  label: string,
} & AnchorHTMLAttributes<HTMLAnchorElement>

type ActionType = ButtonProps | HyperlinkProps;

interface Props {
  title?: string | null;
  description?: string[];
  actions?: ActionType[]
}


export const ErrorCard = ({title, description = [], actions = []}: Props) => {

  return <Card>
    <h5 className={'header'}>
      <SystemIcons.TechnicalWarning size={'24px'}/>
      <span>{title}</span>
    </h5>
    <div>
      {
        description.map((d, i) => <p key={i}>{d}</p>)
      }
    </div>
    {
      actions.map((action, i) => {
        switch (action.componentType) {
          case 'button':
            return <Button key={i}
                           size={Size.Medium}
                           variant="secondary"
                           onClick={action.onClick}
                           width={'max-content'}>
              {action.label}
            </Button>
          case 'hyperlink':
            return <HyperLink key={i} variant={'default'} href={action.href!} {...action}>
              {action.label}
            </HyperLink>
        }
      })
    }
  </Card>
};
