import {useState, useEffect} from 'react';

// Hook
let cachedScripts: any[] = [];

function useScript(apiKey: any, load = true) {
  // Keeping track of script loaded and error state
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(
    () => {
      if (!apiKey) return;

      let src = 'https://sapcdc.laerdal.com/JS/gigya.js?apiKey=' + apiKey;

      window.onGigyaServiceReadyHandlers.push(() => {
        setLoaded(true);
      })

      if (load) {
        // If cachedScripts array already includes src that means another instance ...
        // ... of this hook already loaded this script, so no need to load again.
        if (cachedScripts.includes(src)) {
          setLoaded(true)
          setError(false)
        } else {
          setLoaded(false);
          cachedScripts.push(src);

          // Create script
          let script = document.createElement('script');
          script.src = src;
          script.async = true;

          const onScriptError = () => {
            // Remove from cachedScripts we can try loading again
            const index = cachedScripts.indexOf(src);
            if (index >= 0) cachedScripts.splice(index, 1);
            script.remove();

            setLoaded(true);
            setError(true);
          };

          script.addEventListener('error', onScriptError);

          // Add script to document body
          document.body.appendChild(script);

          const interval = setInterval(() => {
            if (!!window?.gigya?.isReady) {
              setLoaded(true)
              clearInterval(interval)
            }
          }, 100)

          // Remove event listeners on cleanup
          return () => {
            script.removeEventListener('error', onScriptError);
          };
        }
      }
    },
    [apiKey, load], // Only re-run effect if script src changes
  );

  return [loaded, error];
}

export default useScript;
