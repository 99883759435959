import React, {Suspense} from 'react';
import styled from "styled-components";
import {GlobalLoadingPage} from "@laerdal/life-react-components";

const Container = styled.div`
  display: flex;
  min-height: 100%;
`;

export const Layout = ({children}) => {

  return (
    <Container>
      <Suspense fallback={<GlobalLoadingPage/>}>
        {children}
      </Suspense>
    </Container>
  );
}
