import React from 'react';
import {ErrorCard} from "../components/ErrorCard";
import {useTranslation} from "react-i18next";
import ContentContainer from "../components/ContentContainer";

export const NotFound = () => {
  const {t} = useTranslation();

  return (
    <ContentContainer>
      <ErrorCard title={t('Not Found')} description={[t('This page does not exist.')]}/>
    </ContentContainer>
  );
}
