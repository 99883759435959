import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {ScreenSetContainer} from "../../../components/ScreenSetContainer";
import axios from "axios";
import {ServiceProperties} from "../../../models/serviceProperties";


export const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [context, setContext] = useState({});
  const [search] = useSearchParams();

  const buildContext = (properties: ServiceProperties) => {
    if (!properties.serviceBaseUrl || !properties.verifiedEmailRedirectPath) return {};

    return {
      onAfterScreenLoadFn: (event) => {
        if (event.currentScreen === 'gigya-verify-email-success-screen') {
          const button = document.querySelector('[data-screenset-roles="instance"] button[type=submit]');
          button?.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            window.location.href = `${properties.serviceBaseUrl}${properties.verifiedEmailRedirectPath}`
          })
        }
      }
    }
  }

  useEffect(() => {
    const apiKey = search.get('apiKey');

    axios
      .get<ServiceProperties>(`/api/apikeyserviceproperties/${apiKey}`)
      .then((response) => {
        setContext(buildContext(response.data));
        setLoading(false);
      });

  }, [])


  return <ScreenSetContainer startScreen={'gigya-verify-email-success-screen'}
                             context={context}
                             loading={loading}/>
}