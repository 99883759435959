import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import 'url-search-params-polyfill';
import FullWrapper from '../components/FullWrapper';
import {ReactComponent as Illustration} from '../assets/ill1.svg';
import {GlobalLoadingPage, COLORS} from '@laerdal/life-react-components';
import queryString from "query-string";


const RedirectContent = styled.div`
  text-align: center;
  width: fit-content;
`;

const IntroductionTitle = styled.h1`
  font-size: 32px;
  font-weight: 300;
  margin: 0;
  margin-top: 40px;
`;

const RedirectMessage = styled.p`
  font-size: 24px;
  line-height: 1.4;
  font-weight: normal;
  color: black;
  margin: 0;
  margin-top: 10px;

  small {
    display: block;
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: ${COLORS.primary}
  }
`;

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const Redirect = () => {

  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const location = useLocation();
  const hash = location.hash;

  const completeCodeAuthorization = (code, clientId) =>
    axios.post('/api/oidc/token', {
      code: code,
      clientId: clientId,
      redirectUri: window.location.href
    }).then(res => res.data).catch((error) => ({}))

  const retrieveRedirectUrl = (query, clientId = undefined) => {
    axios.get('/api/redirect', {
      params: {
        clientId: clientId,
      },
    }).then((response) => {
      setRedirectUrl(response.data.redirectUri + '#' + query);
    }).catch((error) => console.error(error))
  };


  useEffect(() => {

    if (!hash.includes('id_token')) return;

    const data = queryString.parse(hash);
    const idToken = data['id_token'];
    const accessToken = data['access_token'];
    const code = data['code'];

    let jwt = parseJwt(idToken);

    let params = {id_token: idToken, access_token: accessToken};

    if (code) {
      completeCodeAuthorization(code, jwt.aud).then(res => {
        params = {...params, ...res};
        const query = queryString.stringify(params);
        retrieveRedirectUrl(query);
      });
    } else{
      const query = queryString.stringify(params);
      retrieveRedirectUrl(query, jwt.aud);
    }

  }, [hash]);

  useEffect(() => {
    if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 500)
    }
  }, [redirectUrl]);

  return (
    <>
      {redirectUrl ?
        <FullWrapper>
          <RedirectContent>
            <Illustration/>
            <IntroductionTitle>Opening your app</IntroductionTitle>
            <RedirectMessage>
              <a href={redirectUrl}>Click here if nothing happens</a>
            </RedirectMessage>

          </RedirectContent>
        </FullWrapper>
        : <GlobalLoadingPage/>
      }
    </>
  );
}

export default Redirect;