import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import { GlobalLoadingPage } from '@laerdal/life-react-components';

import ConfigContext from '../../contexts/ConfigContext';

export function Proxy() {
  const config = useContext(ConfigContext);
  const spName = config?.spName;

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `{loginURL: "/saml/login?currentSP=${spName}", logoutURL: "/logout"}`,
            src: 'https://cdns.gigya.com/JS/gigya.saml.js?apiKey=' + config?.apiKey,
          },
        ]}
      />

      <GlobalLoadingPage />
    </>
  );
}
