import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {
  Banner,
  BasicDropdown,
  BREAKPOINTS,
  Button, Checkbox,
  COLORS,
  DropdownFilter, HyperLink,
  InputLabel,
  NoteMessage,
  Size,
  States,
  SystemIcons,
  TextField,
  TooltipWrapper,
  ValidationMessage,
} from '@laerdal/life-react-components';
import {Trans, useTranslation} from 'react-i18next';
import {useController, useForm} from 'react-hook-form';
import {useDynamicInputSize} from '../../../hooks/DynamicSize';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../store';
import {yupResolver} from '@hookform/resolvers/yup';
import {organizationSchema} from '../schema';
import {
  EXCLUDE_VAT_COUNTRIES,
  LOCAL_SEGMENT_COUNTRIES,
  INCLUDE_FEDERAL_TAX_ID_COUNTRIES,
  CORPORATE_SEGMENT_OPTIONS,
  INDUSTRY_OPTIONS, TAX_EXEMPT_COUNTRIES, PO_BOX_COUNTRIES
} from '../config';
import {setOrganizationModel} from '../organization.slice';
import {navigateByUrl} from '../../../utilities/navigate';

const Page = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Note = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: ${COLORS.neutral_100};

  ${BREAKPOINTS.MEDIUM} {
    align-items: center;
    flex-direction: row;
    gap: 16px;
  }

  p {
    color: ${COLORS.neutral_600};
  }

  a {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    span {
      width: max-content;
    }
  }
`;

const Header = styled.h3`
  border-bottom: 1px solid ${COLORS.neutral_200};

  padding-bottom: 32px;
  max-width: unset;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GroupHeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;

  & > div {
    display: flex;
  }
`;

const GroupHeader = styled.h6``;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  border-top: 1px solid ${COLORS.neutral_200};
  gap: 8px;

  button {
    flex: 1;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
  }
`;

const InputWrapper = styled.div`
  input {
    width: 100%;
  }

  [role='listbox'],
  [role='listbox'] > div,
  [role='combobox'],
  [role='combobox'] > div {
    min-width: unset;
  }
`;

const MultiInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  & > div {
    flex: 1;
  }

  input {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;

  .grow {
    flex: 1;
  }

  ${InputWrapper} {
    width: 100%;
  }

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;

    ${InputWrapper}:first-child:nth-last-child(2),
    ${InputWrapper}:first-child:nth-last-child(2) ~ ${InputWrapper},
    ${InputWrapper}:first-child:nth-last-child(3),
    ${InputWrapper}:first-child:nth-last-child(3) ~ ${InputWrapper} {
      width: calc(50% - 4px);
    }
  }

  ${BREAKPOINTS.LARGE} {
    ${InputWrapper}:first-child:nth-last-child(3),
    ${InputWrapper}:first-child:nth-last-child(3) ~ ${InputWrapper} {
      width: calc(33.33% - 4px);
    }
  }
`;

export const OrganizationDetailsStep = () => {
  const {t} = useTranslation('Organization');

  const inputSize = useDynamicInputSize();

  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.organization.value);
  const countries = useAppSelector((state) => state.organization.countries);
  const localSegments = useAppSelector((state) => state.organization.localSegments);
  const [search] = useSearchParams();

  const navigate = useNavigate();

  const {handleSubmit, control, watch, setValue} = useForm({
    defaultValues: {...data},
    resolver: yupResolver(organizationSchema(t)),
    context: {countries} as any,
  });

  const name = useController({name: 'name', control});
  const industry = useController({name: 'industry', control});
  const sfLocalSegment = useController({name: 'sfLocalSegment', control});
  const sfCorporateSegment = useController({name: 'sfCorporateSegment', control});
  const taxExempt = useController({name: 'taxExempt', control});
  const vat = useController({name: 'vatNumber', control});
  const taxId = useController({name: 'federalTaxId', control});

  const firstName = useController({name: 'billingContactPersonFirstName', control});
  const lastName = useController({name: 'billingContactPersonLastName', control});
  const email = useController({name: 'billingEmailAddress', control});

  const billingCompanyName = useController({name: 'address.companyName', control});
  const billingAdditionalName = useController({name: 'address.additionalName', control});
  const billingCountryCode = useController({name: 'address.countryCode', control});
  const billingAddressLine = useController({name: 'address.addressLine', control});
  const billingAddressLineTwo = useController({name: 'address.addressLineTwo', control});
  const billingCity = useController({name: 'address.city', control});
  const billingState = useController({name: 'address.state', control});
  const billingZipCode = useController({name: 'address.zipCode', control});
  const billingPhoneNumber = useController({name: 'address.phoneNumber', control});
  const billingEmail = useController({name: 'address.email', control});


  const shippingAddressSameAsBillingController = useController({name: 'shippingAddressSameAsBilling', control});
  const shippingAdditionalNameController = useController({name: 'shippingAddress.additionalName', control});
  const shippingCountryCodeController = useController({name: 'shippingAddress.countryCode', control});
  const shippingAddressLineController = useController({name: 'shippingAddress.addressLine', control});
  const shippingAddressLineTwoController = useController({name: 'shippingAddress.addressLineTwo', control});
  const shippingCityController = useController({name: 'shippingAddress.city', control});
  const shippingStateController = useController({name: 'shippingAddress.state', control});
  const shippingZipCodeController = useController({name: 'shippingAddress.zipCode', control});
  const shippingPhoneNumberController = useController({name: 'shippingAddress.phoneNumber', control});
  const shippingEmailController = useController({name: 'shippingAddress.email', control});

  const submit = handleSubmit((data) => {
    dispatch(setOrganizationModel(data));
    navigate({
      pathname: `../confirm`,
      search: search.toString(),
    });
  });

  const countryOptions = useMemo(() => countries.map((country) => ({
    displayLabel: country.name,
    value: country.codeAlpha2,
  })), [countries],);

  const country = countries.find((country) => country.codeAlpha2 === billingCountryCode.field.value);
  const shippingCountry = countries.find((country) => country.codeAlpha2 === shippingCountryCodeController.field.value);

  const corporateSegmentOptions = useMemo(() => country?.codeAlpha2 && LOCAL_SEGMENT_COUNTRIES.includes(country?.codeAlpha2) ?
    CORPORATE_SEGMENT_OPTIONS(t).filter(x => localSegments.some(y => y.corporateSegment == x.value && y.countryCode == country?.codeAlpha2))
    : CORPORATE_SEGMENT_OPTIONS(t), [country]);

  const states = useMemo(() => country?.states?.map((state) => ({
    displayLabel: state.name,
    value: state.code,
  })), [country],);

  const shippingStates = useMemo(() => shippingCountry?.states?.map((state) => ({
    displayLabel: state.name,
    value: state.code,
  })) ?? [], [shippingCountry],);


  const segments = useMemo(() =>
      localSegments.filter(x => x.countryCode?.toLowerCase() == country?.codeAlpha2.toLowerCase() &&
        (!sfCorporateSegment.field?.value || x.corporateSegment == sfCorporateSegment.field.value))
        .map(x => ({
          value: x.localSegment,
          displayLabel: x.description
        })),
    [country, sfCorporateSegment]);

  const corporateSegment = watch('sfCorporateSegment');
  const countryCode = watch('address.countryCode');
  const shippingCountryCode = watch('shippingAddress.countryCode');

  useEffect(() => {
    const newIndustry = corporateSegmentOptions.find(x => x.value == sfCorporateSegment.field.value)?.industry;
    if (newIndustry)
      setValue("industry", parseInt(newIndustry));
  }, [corporateSegment]);

  useEffect(() => {
    if (!LOCAL_SEGMENT_COUNTRIES.includes(billingCountryCode.field.value)) {
      setValue('sfCorporateSegment', undefined);
      setValue('sfLocalSegment', undefined);
    }
    if (!shippingCountryCode) setValue('shippingAddress.countryCode', countryCode);
  }, [countryCode]);

  const showShippingAddress = !watch('shippingAddressSameAsBilling');

  return (
    <Page>
      <Wrapper>
        <Header>{t('Create your organization')}</Header>
        {
          <Note>
            <p>{t('If you want to be added to an existing company or organization, please contact Laerdal support.')}</p>
            <HyperLink variant='default' href={process.env.REACT_APP_CONTACT_US_URL!}>
              <span>{t('Contact us')}</span> <SystemIcons.ArrowLineRight/>
            </HyperLink>
          </Note>
        }
        <Group>
          <GroupHeaderContainer>
            <GroupHeader>{t('Billing details')}</GroupHeader>
            <TooltipWrapper label={t('These billing details will be used to address your invoices.')!} position={'top'}
                            align={'center'}>
              <SystemIcons.Information/>
            </TooltipWrapper>
          </GroupHeaderContainer>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'name'} text={t('Company or billing name')} required/>
              <TextField
                id={'name'}
                size={inputSize}
                required
                {...name.field}
                state={name.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={name.fieldState.invalid ? name.fieldState.error!.message! : undefined}
                onChange={(e) => {
                  name.field.onChange(e);
                  billingCompanyName.field.onChange(e);
                }}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'country'} text={t('Country / Region')} required/>
              <DropdownFilter
                id={'country'}
                required
                size={inputSize}
                list={countryOptions}
                multiple={false}
                placeholder={t('Select...')!}
                scrollable={true}
                activeValidationMessage={billingCountryCode.fieldState.invalid ? billingCountryCode.fieldState.error!.message! : undefined}
                name={billingCountryCode.field.name}
                ref={billingCountryCode.field.ref}
                onBlur={billingCountryCode.field.onBlur}
                value={billingCountryCode.field.value}
                disabled={billingCountryCode.field.disabled}
                onSelect={billingCountryCode.field.onChange}
              />
            </InputWrapper>
          </Row>
          {
            !LOCAL_SEGMENT_COUNTRIES.includes(billingCountryCode.field.value) &&
            <Row>

              <InputWrapper>
                <InputLabel inputId={'industry'} text={t('Industry')} required/>
                <BasicDropdown
                  id={'industry'}
                  //@ts-ignore
                  required
                  size={inputSize}
                  list={INDUSTRY_OPTIONS(t)}
                  placeholder={t('Select...')!}
                  activeValidationMessage={industry.fieldState.invalid ? industry.fieldState.error!.message! : undefined}
                  ref={industry.field.ref}
                  onBlur={industry.field.onBlur}
                  value={`${industry.field.value}`}
                  disabled={industry.field.disabled}
                  onSelect={industry.field.onChange}
                />
              </InputWrapper>
            </Row>
          }
          {
            LOCAL_SEGMENT_COUNTRIES.includes(billingCountryCode.field.value) &&
            <Row>
              <InputWrapper>
                <InputLabel inputId={'sfCorporateSegment'} text={t('Industry')} required/>
                <BasicDropdown
                  id={'sfCorporateSegment'}
                  //@ts-ignore
                  required
                  size={inputSize}
                  list={corporateSegmentOptions}
                  placeholder={t('Select...')!}
                  activeValidationMessage={sfCorporateSegment.fieldState.invalid ? sfCorporateSegment.fieldState.error!.message! : undefined}
                  ref={sfCorporateSegment.field.ref}
                  onBlur={sfCorporateSegment.field.onBlur}
                  value={`${sfCorporateSegment.field.value}`}
                  disabled={sfCorporateSegment.field.disabled}
                  onSelect={sfCorporateSegment.field.onChange}
                />
              </InputWrapper>
              <InputWrapper>
                <InputLabel inputId={'sfLocalSegment'} text={t('Category')} required/>
                <BasicDropdown
                  id={'sfLocalSegment'}
                  //@ts-ignore
                  required
                  size={inputSize}
                  list={segments}
                  placeholder={t('Select...')!}
                  activeValidationMessage={sfLocalSegment.fieldState.invalid ? sfLocalSegment.fieldState.error!.message! : undefined}
                  ref={sfLocalSegment.field.ref}
                  onBlur={sfLocalSegment.field.onBlur}
                  value={`${sfLocalSegment.field.value}`}
                  disabled={sfLocalSegment.field.disabled}
                  onSelect={sfLocalSegment.field.onChange}
                />
              </InputWrapper>
            </Row>
          }

          <Row>
            <InputWrapper>
              <InputLabel inputId={'firstName'} text={t('Billing representative')} required/>
              <MultiInputWrapper>
                <TextField
                  id={'firstName'}
                  required
                  placeholder={t('First name')!}
                  state={lastName.fieldState.invalid || firstName.fieldState.invalid ? States.Invalid : undefined}
                  size={inputSize}
                  {...firstName.field}
                />
                <TextField
                  id={'lastName'}
                  required
                  placeholder={t('Last name')!}
                  state={lastName.fieldState.invalid || firstName.fieldState.invalid ? States.Invalid : undefined}
                  size={inputSize}
                  {...lastName.field}
                />
              </MultiInputWrapper>
              {(lastName.fieldState.invalid || firstName.fieldState.invalid) && (
                <ValidationMessage
                  type={States.Invalid}>{firstName.fieldState?.error?.message || lastName.fieldState?.error?.message}</ValidationMessage>
              )}
              {!lastName.fieldState.invalid && !firstName.fieldState.invalid &&
                <NoteMessage>{t('The name of a person who handles invoices.')}</NoteMessage>}
            </InputWrapper>
          </Row>

          <Row>
            <InputWrapper>
              <InputLabel inputId={'email'} text={t('Billing email address')} required/>
              <TextField
                id={'email'}
                required
                size={inputSize}
                state={email.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={email.fieldState.invalid ? email.fieldState.error!.message! : undefined}
                note={
                  !email.fieldState.invalid
                    ? {
                      message: t('Receipts and invoices will be sent to this email address.'),
                      icon: <></>,
                    }
                    : undefined
                }
                {...email.field}
              />
            </InputWrapper>
          </Row>
          {INCLUDE_FEDERAL_TAX_ID_COUNTRIES.includes(billingCountryCode.field.value) && (
            <Row>
              <InputWrapper>
                <InputLabel inputId={'taxId'} text={t('Federal Tax ID')} required/>
                <TextField
                  id={'taxId'}
                  required
                  size={inputSize}
                  state={taxId.fieldState.invalid ? States.Invalid : undefined}
                  validationMessage={taxId.fieldState.invalid ? taxId.fieldState.error!.message! : undefined}
                  {...taxId.field}
                />
              </InputWrapper>
            </Row>
          )}
          <Row>
            {!EXCLUDE_VAT_COUNTRIES.includes(billingCountryCode.field.value) && (
              <InputWrapper>
                <InputLabel inputId={'vat'} text={t('Business / Tax ID')}/>
                <TextField
                  id={'vat'}
                  size={inputSize}
                  state={vat.fieldState.invalid ? States.Invalid : undefined}
                  validationMessage={vat.fieldState.invalid ? vat.fieldState.error!.message! : undefined}
                  {...vat.field}
                />
              </InputWrapper>
            )}
          </Row>
          {
            TAX_EXEMPT_COUNTRIES.includes(billingCountryCode.field.value) &&
            <Row>
              <Checkbox size={Size.Small}
                        selected={!!taxExempt.field.value}
                        select={taxExempt.field.onChange}
                        ref={taxExempt.field.ref}
                        disabled={taxExempt.field.disabled}
                        onBlur={taxExempt.field.onBlur}>
                <Trans ns={'Organization'} i18nKey={'TaxExemptCheckbox'}>
                  Yes, I am tax exempt <HyperLink variant={'default'} href={process.env.REACT_APP_TAX_EXEMPT_INFO_URL!}>Learn
                  more</HyperLink>
                </Trans>
              </Checkbox>
            </Row>
          }
        </Group>

        <Group>
          <Row>
            <GroupHeader>{t('Billing address')}</GroupHeader>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'company'} text={t('Company or billing name')}/>
              <TextField id={'company'} readOnly={true} size={inputSize} {...billingCompanyName.field} />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'additional'} text={t('Additional names (optional)')}/>
              <TextField
                id={'additional'}
                size={inputSize}
                state={billingAdditionalName.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingAdditionalName.fieldState.invalid ? billingAdditionalName.fieldState.error!.message! : undefined}
                {...billingAdditionalName.field}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'addressLine'} text={t('Address line 1')} required/>
              <TextField
                id={'addressLine'}
                required
                size={inputSize}
                state={billingAddressLine.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingAddressLine.fieldState.invalid ? billingAddressLine.fieldState.error!.message! : undefined}
                note={
                  !billingAddressLine.fieldState.invalid
                    ? {
                      message: t('Add street number first, if applicable'),
                      icon: <></>,
                    }
                    : undefined
                }
                {...billingAddressLine.field}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'addressLineTwo'} text={t('Address line 2')}/>
              <TextField
                id={'addressLineTwo'}
                size={inputSize}
                state={billingAddressLineTwo.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingAddressLineTwo.fieldState.invalid ? billingAddressLineTwo.fieldState.error!.message! : undefined}
                note={
                  !billingAddressLineTwo.fieldState.invalid
                    ? {
                      message: t('Building, department, floor, etc.'),
                      icon: <></>,
                    }
                    : undefined
                }
                {...billingAddressLineTwo.field}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'city'} text={t('City')} required/>
              <TextField
                id={'city'}
                required
                size={inputSize}
                state={billingCity.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingCity.fieldState.invalid ? billingCity.fieldState.error!.message! : undefined}
                {...billingCity.field}
              />
            </InputWrapper>
            {!!states?.length && (
              <InputWrapper>
                <InputLabel inputId={'state'} text={t('State / Province')} required/>
                <DropdownFilter
                  id={'state'}
                  required
                  size={inputSize}
                  list={states}
                  multiple={false}
                  placeholder={t('Select...')!}
                  scrollable={true}
                  activeValidationMessage={billingState.fieldState.invalid ? billingState.fieldState.error!.message! : undefined}
                  name={billingState.field.name}
                  ref={billingState.field.ref}
                  onBlur={billingState.field.onBlur}
                  value={billingState.field.value}
                  disabled={billingState.field.disabled}
                  onSelect={billingState.field.onChange}
                />
              </InputWrapper>
            )}
            <InputWrapper className={'grow'}>
              <InputLabel inputId={'zipCode'} text={t('Zip / Postal Code')} required/>
              <TextField
                id={'zipCode'}
                required
                size={inputSize}
                state={billingZipCode.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingZipCode.fieldState.invalid ? billingZipCode.fieldState.error!.message! : undefined}
                {...billingZipCode.field}
              />
            </InputWrapper>
          </Row>
          <Row>
            <InputWrapper>
              <InputLabel inputId={'phone'} text={t('Telephone and area code')} required/>
              <TextField
                id={'phone'}
                required
                size={inputSize}
                placeholder={'(___) ___-____'}
                state={billingPhoneNumber.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingPhoneNumber.fieldState.invalid ? billingPhoneNumber.fieldState.error!.message! : undefined}
                note={
                  !billingPhoneNumber.fieldState.invalid
                    ? {
                      message: t('In case we need to contact you about your order(s).'),
                      icon: <></>,
                    }
                    : undefined
                }
                {...billingPhoneNumber.field}
              />
            </InputWrapper>

            <InputWrapper>
              <InputLabel inputId={'billingAddressEmail'} text={t('Email address (optional)')}/>
              <TextField
                id={'billingAddressEmail'}
                size={inputSize}
                state={billingEmail.fieldState.invalid ? States.Invalid : undefined}
                validationMessage={billingEmail.fieldState.invalid ? billingEmail.fieldState.error!.message! : undefined}
                note={
                  !billingEmail.fieldState.invalid
                    ? {
                      message: t('For email updates about your order(s).'),
                      icon: <></>,
                    }
                    : undefined
                }
                {...billingEmail.field}
              />
            </InputWrapper>

          </Row>
        </Group>


        <Group>
          <Row>
            <GroupHeader>{t('Shipping address')}</GroupHeader>
          </Row>
          <Row>
            <Checkbox size={Size.Small}
                      selected={!!shippingAddressSameAsBillingController.field.value}
                      select={shippingAddressSameAsBillingController.field.onChange}
                      ref={shippingAddressSameAsBillingController.field.ref}
                      disabled={shippingAddressSameAsBillingController.field.disabled}
                      onBlur={shippingAddressSameAsBillingController.field.onBlur}>
              <Trans ns={'Organization'} i18nKey={'ShippingAddressText'}>
                Same as billing address
              </Trans>
            </Checkbox>
          </Row>

          {
            showShippingAddress &&
            <>
              {
                PO_BOX_COUNTRIES.includes(billingCountryCode.field.value) &&
                <Row>
                  <Banner type='neutral' icon={<></>} className={'grow'}>
                    {t('Please note that we do not ship to PO boxes.')}
                  </Banner>
                </Row>
              }
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingCompany'} text={t('Company or billing name')}/>
                  <TextField id={'shippingCompany'} readOnly={true} size={inputSize} {...billingCompanyName.field} />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingAdditional'} text={t('Additional names (optional)')}/>
                  <TextField
                    id={'shippingAdditional'}
                    size={inputSize}
                    state={shippingAdditionalNameController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingAdditionalNameController.fieldState.invalid ? shippingAdditionalNameController.fieldState.error!.message! : undefined}
                    {...shippingAdditionalNameController.field}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingCountry'} text={t('Country / Region')} required/>
                  <DropdownFilter
                    id={'shippingCountry'}
                    required
                    size={inputSize}
                    list={countryOptions}
                    multiple={false}
                    placeholder={t('Select...')!}
                    scrollable={true}
                    activeValidationMessage={shippingCountryCodeController.fieldState.invalid ? shippingCountryCodeController.fieldState.error!.message! : undefined}
                    name={shippingCountryCodeController.field.name}
                    ref={shippingCountryCodeController.field.ref}
                    onBlur={shippingCountryCodeController.field.onBlur}
                    value={shippingCountryCodeController.field.value}
                    disabled={shippingCountryCodeController.field.disabled}
                    onSelect={shippingCountryCodeController.field.onChange}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingAddressLineController'} text={t('Address line 1')} required/>
                  <TextField
                    id={'shippingAddressLineController'}
                    required
                    size={inputSize}
                    state={shippingAddressLineController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingAddressLineController.fieldState.invalid ? shippingAddressLineController.fieldState.error!.message! : undefined}
                    note={
                      !shippingAddressLineController.fieldState.invalid
                        ? {
                          message: t('Add street number first, if applicable'),
                          icon: <></>,
                        }
                        : undefined
                    }
                    {...shippingAddressLineController.field}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingAddressLineTwoController'} text={t('Address line 2')}/>
                  <TextField
                    id={'shippingAddressLineTwoController'}
                    size={inputSize}
                    state={shippingAddressLineTwoController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingAddressLineTwoController.fieldState.invalid ? shippingAddressLineTwoController.fieldState.error!.message! : undefined}
                    note={
                      !shippingAddressLineTwoController.fieldState.invalid
                        ? {
                          message: t('Building, department, floor, etc.'),
                          icon: <></>,
                        }
                        : undefined
                    }
                    {...shippingAddressLineTwoController.field}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingCityController'} text={t('City')} required/>
                  <TextField
                    id={'shippingCityController'}
                    required
                    size={inputSize}
                    state={shippingCityController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingCityController.fieldState.invalid ? shippingCityController.fieldState.error!.message! : undefined}
                    {...shippingCityController.field}
                  />
                </InputWrapper>
                {!!shippingStates?.length && (
                  <InputWrapper>
                    <InputLabel inputId={'shippingStateController'} text={t('State / Province')} required/>
                    <DropdownFilter
                      id={'shippingStateController'}
                      required
                      size={inputSize}
                      list={shippingStates}
                      multiple={false}
                      placeholder={t('Select...')!}
                      scrollable={true}
                      activeValidationMessage={shippingStateController.fieldState.invalid ? shippingStateController.fieldState.error!.message! : undefined}
                      name={shippingStateController.field.name}
                      ref={shippingStateController.field.ref}
                      onBlur={shippingStateController.field.onBlur}
                      value={shippingStateController.field.value}
                      disabled={shippingStateController.field.disabled}
                      onSelect={shippingStateController.field.onChange}
                    />
                  </InputWrapper>
                )}
                <InputWrapper className={'grow'}>
                  <InputLabel inputId={'shippingZipCodeController'} text={t('Zip / Postal Code')} required/>
                  <TextField
                    id={'shippingZipCodeController'}
                    required
                    size={inputSize}
                    state={shippingZipCodeController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingZipCodeController.fieldState.invalid ? shippingZipCodeController.fieldState.error!.message! : undefined}
                    {...shippingZipCodeController.field}
                  />
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper>
                  <InputLabel inputId={'shippingPhone'} text={t('Telephone and area code')} required/>
                  <TextField
                    id={'shippingPhone'}
                    required
                    size={inputSize}
                    placeholder={'(___) ___-____'}
                    state={shippingPhoneNumberController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingPhoneNumberController.fieldState.invalid ? shippingPhoneNumberController.fieldState.error!.message! : undefined}
                    note={
                      !shippingPhoneNumberController.fieldState.invalid
                        ? {
                          message: t('In case we need to contact you about your order(s).'),
                          icon: <></>,
                        }
                        : undefined
                    }
                    {...shippingPhoneNumberController.field}
                  />
                </InputWrapper>

                <InputWrapper>
                  <InputLabel inputId={'shippingAddressEmail'} text={t('Email address (optional)')}/>
                  <TextField
                    id={'shippingAddressEmail'}
                    size={inputSize}
                    state={shippingEmailController.fieldState.invalid ? States.Invalid : undefined}
                    validationMessage={shippingEmailController.fieldState.invalid ? shippingEmailController.fieldState.error!.message! : undefined}
                    note={
                      !shippingEmailController.fieldState.invalid
                        ? {
                          message: t('For email updates about your order(s).'),
                          icon: <></>,
                        }
                        : undefined
                    }
                    {...shippingEmailController.field}
                  />
                </InputWrapper>

              </Row>
            </>
          }

        </Group>

        <Actions>
          <Button variant="primary" onClick={submit} size={Size.Large}>
            {t('Continue')}
          </Button>
          {!!search.get('onCancel') && (
            <Button variant="tertiary" onClick={() => navigateByUrl(search.get('onCancel')!, navigate)}
                    size={Size.Large}>
              {t('Cancel')}
            </Button>
          )}
        </Actions>
      </Wrapper>
    </Page>
  );
};
