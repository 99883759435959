import axios, {AxiosInstance} from "axios";
import {SessionInfo} from "../../models/sessionInfo";
import Cookies from "universal-cookie";


class TokenLoginService {

  private _client: AxiosInstance;

  constructor() {
    this._client = axios.create();
  }

  private CreateCookie = (cookieName: string, cookieValue: string, expires?: Date): void => {
    new Cookies().set(
      cookieName,
      cookieValue,
      {
        path: '/',
        expires: expires,
        sameSite: 'none',
        secure: true,
        domain: '.laerdal.com'
      });
  };

  SignIn = async (token: string): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      window.gigya?.accounts?.logout({
        callback: () => {
          this._client.post<SessionInfo>(`/api/user/login?token=${token}`)
            .then(a => {
              this.CreateCookie(a.data.cookieName, a.data.cookieValue,);
              window.gigya?.socialize?.refreshUI({
                callback: (resp: any) => {
                  if (resp.errorCode === 0) {
                    resolve(true)
                  } else {
                    resolve(false);
                  }
                },
              });
            })
            .catch(() => resolve(false));
        }
      });
    })
  };
}

export default new TokenLoginService();