import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSuspendWhileFetching} from "../../hooks/SuspendWhileFetching";
import {GlobalLoadingPage} from "@laerdal/life-react-components";
import TokenLoginService from "./TokenLoginService";


export const TokenLoginPage = () => {

  const [search] = useSearchParams();
  const navigate = useNavigate();

  const token = search.get('token');
  const returnUrl = search.get('returnUrl');

  const [result] = useSuspendWhileFetching(() => TokenLoginService.SignIn(token!), `token_login_${token}`);

  useEffect(() => {
    if (result) {
      window.location.href = returnUrl || process.env.REACT_APP_CONNECT_URL!;
    } else {
      navigate(`/login?returnUrl=${returnUrl || ''}`)
    }
  }, [result]);

  return (<GlobalLoadingPage/>);
}