/**
 * Import React libraries.
 */
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

/**
 * Import third-party libraries.
 */
import {GlobalLoadingPage} from '@laerdal/life-react-components';
import axios from 'axios';

/**
 * Import custom hooks.
 */
import useScript from '../hooks/useScript';

export function FedRampSSO() {
  /**
   * Globally used variables within the component.
   */
  const location = useLocation();
  const [apiKey, setApiKey] = useState<string>('');
  const [source, setSource] = useState<string>('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [loaded] = useScript(apiKey, true);

  /**
   * Does all required pre-requisites to do FedRamp SSO.
   */
  useEffect(() => {
    // Let's get URL params
    const parameters = new URLSearchParams(location.search);

    // Let's retrieve required parameters from the url
    if (parameters.get('source')) {
      setSource(parameters.get('source')!);
    }
    if (parameters.get('redirectUrl')) {
      setRedirectUrl(parameters.get('redirectUrl')!);
    }

    retrieveConfig();
  }, []);

  /**
   * Call socialize login when api key web sdk is loaded.
   */
  useEffect(() => {
    if (loaded) {
      window.gigya.accounts.verifyLogin({
        callback: (e) => {
          if (!e.UID) {
            // Let's redirect
            window.location.href = `${redirectUrl}?noSession=true`;
          } else {
            // Let's init SSO
            initSSO();
          }
        }
      });
    }
  }, [loaded]);

  /**
   * Initiates the SSO.
   */
  const initSSO = () => {
    window.gigya.fidm.saml.initSSO({
      spName: source,
      redirectURL: redirectUrl
    });
  };

  /**
   * Retrieves the api key for a source site.
   */
  const retrieveConfig = () => {
    axios
      .get('/api/config', {
        params: {
          scope: source,
        },
      })
      .then((response) => {
        if (response.data && response.data.apiKey) {
          // Let's store the api key
          setApiKey(response.data.apiKey)
        }
      })
      .catch((error) => console.error(error));
  };

  // Return the page template
  return <GlobalLoadingPage/>;
}
