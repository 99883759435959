import styled from 'styled-components'
import {BREAKPOINTS, COLORS} from "@laerdal/life-react-components";

const ContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: ${COLORS.neutral_50};
  padding: 48px 16px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  ${BREAKPOINTS.MEDIUM} {
    padding: 56px 24px 24px;
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    padding: 64px 32px 32px;
    gap: 32px;
  }
`;

export default ContentContainer;