import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {ScreenSetContainer} from "../../components/ScreenSetContainer";

export const SSOCallback = () => {
  const [search] = useSearchParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [startScreen, setStartScreen] = useState('');
  const context = useRef<any>({});

  const redirect = () => {
    window.location.href = search.get('returnUrl')!;
  }

  const notifyLogin = (uid: any, sig: any, timestamp: any) => {
    setLoading(true)
    window.gigya.socialize.notifyLogin({
      siteUID: uid,
      UIDSig: sig,
      UIDTimestamp: timestamp,
      callback: () => redirect(),
    });
  }

  const handleAccountLink = (e: any) => {
    if (e.form === 'gigya-link-accounts-form' && e.response.errorCode === 0) {
      const returnUrl = search.get('returnUrl');
      const idp = search.get('idp');
      const loginID = search.get('loginID');
      const redirectUrl = '/sso/initiate?returnUrl=' + returnUrl + '&idp=' + idp + '&loginID=' + encodeURIComponent(loginID!);
      navigate(redirectUrl);
    }
  }

  useEffect(() => {
    delete window.gigya.thisScript.globalConf.ignoreInterruptions;
  }, [])

  useEffect(() => {
    const gigEvents = search.get('gig_events');
    if (!gigEvents) return;

    if (gigEvents === 'socialize.login') {
      const errorCode = search.get('errorCode');
      context.current.regToken = search.get('regToken');
      context.current.loginID = search.get('loginID');

      switch (errorCode) {
        case '0':
          const UID = search.get('UID');
          const UIDSignature = search.get('UIDSignature');
          const signatureTimestamp = search.get('signatureTimestamp');
          notifyLogin(UID, UIDSignature, signatureTimestamp);
          break;
        case '206001':
          context.current.onAfterSubmitFn = (e: any) => notifyLogin(e.response.UID, e.response.UIDSignature, e.response.signatureTimestamp);
          setStartScreen('gigya-complete-registration-screen');
          setLoading(false);
          break;
        case '403043':
          context.current.onAfterSubmitFn = (e: any) => handleAccountLink(e);
          setStartScreen('Auth2-LinkAccounts/gigya-link-account-screen');
          setLoading(false);
          break;
        default:
          const returnUrl = search.get('returnUrl')!;
          const loginID = search.get('loginID')!;
          const url = new URL(returnUrl)
          url.searchParams.append('event', 'SSO_FAILED');
          url.searchParams.append('loginID', loginID);
          window.location.href = url.href;
          break;
      }
    }
  }, [])

  return <ScreenSetContainer loading={loading} startScreen={startScreen} context={context.current}/>
}