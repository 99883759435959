import React, {useState, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import axios from 'axios';
import {Language} from "../models/language";

export const ConfigContext = React.createContext<{ apiKey: any, spName: any, loaded: any, languages: Language[] } | null>(null);

/**
 * @param props
 * @constructor
 * @description This component provides configuration and information about enabled features
 */
export const ConfigProvider = (props: any) => {
  const {children} = props;
  const [apiKey, setApiKey] = useState<any>(null);
  const [loaded, setLoaded] = useState<any>(false);
  const [spName, setSpName] = useState<any>(null);
  const [languages, setLanguages] = useState<Language[]>([]);

  let [query] = useSearchParams();

  useEffect(() => {
    let clientScope = sessionStorage.getItem('clientScope');
    let scopes = query.get('scope');
    let clientScopeString = scopes?.split(' ').find(function (scope) {
      return scope.startsWith('fed_');
    });
    let spNameString = query.get('spName');

    if (!apiKey) {
      if (clientScopeString) {
        sessionStorage.setItem('clientScope', clientScopeString);
        clientScope = clientScopeString;
      }

      if (spNameString) {
        setSpName(spNameString);
        sessionStorage.setItem('clientScope', spNameString);
        clientScope = spNameString;
      }

      let configRequest = axios.get('/api/config', {
          params: {
            scope: clientScope,
          },
        })
        .then((response) => {
          setApiKey(response.data.apiKey);
        })
        .catch((error) => console.error(error));

      let languagesRequest = axios
        .get('/api/config/languages')
        .then((response) => {
          setLanguages(response.data);
        })
        .catch((error) => console.error(error));

      Promise.all([configRequest, languagesRequest]).then(() => setLoaded(true));
    }
  }, [apiKey]);

  /**
   * The provider wraps (parts of) the react component tree, and "exposes" data and functions
   */
  return (
    <ConfigContext.Provider
      value={{
        apiKey,
        spName,
        loaded,
        languages,
      }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
