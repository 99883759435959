import {GlobalLoadingPage} from "@laerdal/life-react-components";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const SSOInitiate = () => {

  const {search} = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (!query.get('idp')) return;

    const idp = query.get('idp');
    const lang = query.get('lng');
    const loginID = query.get('loginID');
    const returnUrl = encodeURIComponent(query.get('returnUrl')!);
    let redirectUrl = window.location.origin + '/sso/callback?returnUrl=' + returnUrl + '&idp=' + idp + '&loginID=' + encodeURIComponent(loginID!);
    if (lang) {
      redirectUrl += '&lng=' + lang;
    }

    window.gigya.socialize.login({
      provider: 'saml-' + idp,
      authFlow: 'redirect',
      redirectURL: redirectUrl
    });

  }, [query])

  return <GlobalLoadingPage/>
}