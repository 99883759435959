import axios, {AxiosInstance} from "axios";
import dayjs from "dayjs";
import Aes from 'crypto-js/aes';
import Cookies from "universal-cookie";
import {SignInOnBehalfResponse} from "../../models/signInOnBehalfResponse";


class SignInOnBehalfService {

  private _client: AxiosInstance;

  constructor() {
    this._client = axios.create();
  }

  private CreateSingInOnBehalfCookie = (value: string, identityId: string): void => {
    const name = 'laerdal-signin-on-behalf';
    const expires = dayjs().add(15, 'minutes').toDate();

    const encrypted = Aes.encrypt(JSON.stringify({expires: expires, cookieValue: value, identityId}), name).toString();

    this.CreateCookie(name, encrypted);
  }

  private CreateCookie = (cookieName: string, cookieValue: string, expires?: Date): void => {
    new Cookies().set(
      cookieName,
      cookieValue,
      {
        path: '/',
        expires: expires,
        sameSite: 'none',
        secure: true,
        domain: '.laerdal.com'
      });
  };

  SigninOnBehalf = async (id: string): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      window.gigya?.accounts?.logout({
        callback: () => {
          this._client.post<SignInOnBehalfResponse>(`/api/user/impersonate/${id}`)
            .then(a => {
              this.CreateCookie(a.data.session.cookieName, a.data.session.cookieValue, dayjs().add(15, 'minutes').toDate());
              this.CreateSingInOnBehalfCookie(a.data.session.cookieValue, a.data.identityId);

              // @ts-ignore
              window.gigya?.socialize?.refreshUI({
                callback: (resp: any) => {
                  if (resp.errorCode === 0) {
                    window.location.href = a.data?.redirectUrl;
                  } else {
                    resolve(false);
                  }
                },
              });

              resolve(true);
            })
            .catch(() => resolve(false));
        }
      })
    })
  };
}

export default new SignInOnBehalfService();