import {ToastOptions} from "@laerdal/life-react-components/dist/Toasters/Toast";
import {ToastColor, ToastPosition} from "@laerdal/life-react-components";

export const SuccessToastOptions: ToastOptions = {
  color: ToastColor.GREEN,
  showCloseButton: true,
  autoClose: true,
  position: ToastPosition.TOPMIDDLE,
}

export const FailToastOptions: ToastOptions = {
  color: ToastColor.RED,
  showCloseButton: true,
  autoClose: true,
  position: ToastPosition.TOPMIDDLE,
}

