import React, {useState, useEffect, useContext} from 'react';

import {AuthPage} from '@laerdal/life-react-components';

import SignInPlaceholderImage from '../../../assets/portal-sign-in-placeholder.svg';
import SignUpPlaceholderImage from '../../../assets/portal-sign-up-placeholder.svg';
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const LegacyLogin = () => {
  const {i18n} = useTranslation();
  const [registration, setRegistration] = useState(false);

  const tieImageToScreen = (e) => {
    if (e?.nextScreen === 'gigya-register-screen') {
      setRegistration(true);
    } else {
      setRegistration(false);
    }
  };

  useEffect(() => {
    window.gigya.socialize.addEventHandlers({
      onLogin: () => {
        window.location.href = window.gigya.utils.URL.addParamsToURL('proxy', {mode: 'afterLogin'});
      },
    });

    window.gigya.accounts.showScreenSet({
      screenSet: 'Default-RegistrationLogin',
      containerID: 'screenset-container',
      lang: i18n.language?.toLowerCase(),
      context: {
        onBeforeScreenLoadFn: tieImageToScreen,
      }
    });
  }, []);

  return (
    <Container>
      <AuthPage
        panel={{
          media: {
            type: 'image',
            src: registration ? SignUpPlaceholderImage : SignInPlaceholderImage,
            alt: registration ? 'Man and woman gesticulating while another man is sitting down with a tablet' : 'Man and woman gesticulating. The woman points to the sign-in form on the right'
          },
          title: registration ? 'One account. All of Laerdal.' : 'Welcome to Laerdal',
          content: registration ? 'Create a Laerdal account today to access these services' : 'Sign in with your account'
        }}
        screenSetsContainerId={'screenset-container'}
      />
    </Container>
  );
};

export default LegacyLogin;
