import React, {useContext, useEffect, useRef} from "react";
import {ScreenSetContainer} from "../../../components/ScreenSetContainer";
import {InvitationError} from "./InvitationError";
import {useParams, useSearchParams} from "react-router-dom";
import axios from 'axios';
import {ToastContext} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {FailToastOptions, SuccessToastOptions} from "../../../models/toast";
import {IsServiceUserRole} from "../../../utilities/ServiceRoleHelpers";

export const Invitation = () => {

  const {t} = useTranslation('Invitation')
  const {addToast} = useContext(ToastContext);
  const [state, setState] = React.useState<'loading' | 'active' | 'inactive' | 'invalid-login'>("loading");
  const [account, setAccount] = React.useState<{ profile: { email: string } } | null>(null);

  const context = useRef<any>({});

  const [search] = useSearchParams();
  const {type, code} = useParams();

  const getAccountInfo = () => {
    return new Promise<{ profile: { email: string }, errorCode: number }>((resolve, reject) => {
      window.gigya.accounts.getAccountInfo({
        callback: (response) => {
          resolve(response);
        }
      })
    });
  }

  const onInvitationAccepted = () => {
    addToast(t('You have successfully joined {{organization}}', {organization: context.current.invitation.data.organization}), SuccessToastOptions);

    let url = '';
    switch (type) {
      case 'organization':
        url = search.get('returnUrl') || process.env.REACT_APP_CONNECT_URL!;
        break;
      case 'service':
        const serviceId = context.current.invitation.data.serviceId;
        const serviceUrl = context.current.invitation.data.serviceUrl;
        const userRole = IsServiceUserRole(context.current.invitation.data.role);
        url = serviceUrl
          ? serviceUrl
          : userRole
            ? `${process.env.REACT_APP_CONNECT_URL}/services/welcome/${serviceId}`
            : `${process.env.REACT_APP_CONNECT_URL}`
        break;
    }

    setTimeout(() => window.location.href = url, 1500);
    return Promise.resolve();
  }

  const onInvitationFailed = () => {
    switch (type) {
      case 'organization':
        addToast(t('You could not join {{organization}}', {organization: context.current.invitation.data.organization}), FailToastOptions);
        break;
      case 'service':
        addToast(t('You could not join {{organization}}', {organization: context.current.invitation.data.organization}), FailToastOptions);
        break;
    }

    return Promise.resolve();
  }

  useEffect(() => {
    axios
      .get(`/api/invitation/${type}/${code}`)
      .then((response) => {

        context.current.invitation = {type, code, data: response.data};
        context.current.loginID = response.data.email;
        context.current.onInvitationAccepted = onInvitationAccepted;
        context.current.onInvitationFailed = onInvitationFailed;

        if (!response.data.isActive) {
          setState("inactive");
        } else {
          getAccountInfo().then((accountInfo) => {
            setAccount(accountInfo)
            if (accountInfo.errorCode === 0) {
              if (accountInfo?.profile?.email !== response.data.email) {
                setState("invalid-login");
              } else {
                setState('active');
                context.current.invitation.autoAccept = true;
              }
            } else {
              setState('active')
            }
          })
        }
      })
      .catch((error) => {
        setState("inactive");
      });
  }, [type, code])

  return (
    <>
      {
        (state === 'active' || state === 'loading') &&
        <ScreenSetContainer startScreen={state === 'active' ? 'laerdal-invitation-screen' : undefined}
                            loading={state === 'loading'}
                            context={context.current}/>
      }
      {
        (state === 'inactive' || state === 'invalid-login') &&
        <InvitationError state={state} email={account?.profile?.email}/>
      }
    </>
  )
}