import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import {useSearchParams} from "react-router-dom";
import {ErrorCard} from "../components/ErrorCard";
import {useTranslation} from "react-i18next";
import ContentContainer from "../components/ContentContainer";


export const Error = () => {

  const {t} = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const [search] = useSearchParams()

  useEffect(() => {
    const message = search.get('message');
    const errorCode = search.get('ErrorCode');
    const errorDescription = search.get('ErrorDescription');
    const errorDetails = search.get('ErrorDetails');
    if (message) {
      setErrors([message]);
    } else if (errorCode && errorDescription && errorDetails) {
      setErrors([errorDescription, errorDetails]);
    } else {
      setErrors([t('Unknown error')]);
    }
  }, [])

  return (
    <ContentContainer>
      <ErrorCard title={t('An error occured trying to log you in')}
                 description={errors}/>
    </ContentContainer>
  );
}
